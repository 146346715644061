<template>
  <CModal
    @update:show="closeModal"
    :title="`${eventTypes[event]} category template`"
    color="primary"
    :show="showModal"
    centered
  >
    <CInput label="Name" placeholder="Enter your name" v-model="item.name" />
  </CModal>
</template>

<script>
import { CModal, CInput } from "@coreui/vue/src";
export default {
  name: "TemplateCategoryModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          name: "default name",
        };
      },
    },
    event: {
      type: String,
      default: "create",
    },
  },
  components: {
    CInput,
    CModal,
  },
  data() {
    return {
      eventTypes: {
        create: "Create",
        edit: "Edit",
      },
    };
  },
  methods: {
    closeModal(show, event, accept) {
      if (accept) {
        this.$emit("accept");
      } else {
        this.$emit("close");
      }
    },
  }
};
</script>

<style scoped></style>
